import type { IGamesPage } from '~/types';

type Maybe<T> = T | null | undefined;

interface IPageContent<T> {
  currentLocaleData: Maybe<T>;
  defaultLocaleData: Maybe<T>;
}

export const useGamesContentStore = defineStore('gamesContent', () => {
  const { getContentData } = useContentLogic<IGamesPage>({
    contentKey: 'gamesPageContent',
    contentRoute: ['pages', 'games'],
    isPage: false,
  });

  const { data: content, pending: loading } = useAsyncData<IPageContent<IGamesPage>>('games-content', getContentData, {
    server: true,
    lazy: false,
    immediate: true,
  });

  return {
    content,
    loading,
  };
});
